document.body.classList.add('loaded');

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// Gsap Animation

gsap.registerPlugin(ScrollTrigger);

function initializeGSAPAnimations() {
	gsap.utils.toArray(".section").forEach((section) => {
		gsap.to(section, {
			scrollTrigger: {
				trigger: section,
				start: "top 80%",
				end: "bottom top",
				onEnter: () => {
					section.classList.add("visible-underline");
				}
			}
		});
	});
}

initializeGSAPAnimations();


const sectionContent = document.querySelector('body');

if (sectionContent) {
	Array.from(sectionContent.querySelectorAll(
		'.wysywig h1, .wysywig h2, .wysywig h3, .wysywig h4, .wysywig h5, .wysywig h6, .wysywig .h1, .wysywig .h2, .wysywig .h3, .wysywig li, .wysywig p, .gsap'
	)).forEach((element) => {
		gsap.from(element, 0.8, { scrollTrigger: element, y: '40px', opacity: 0 })
	});
}

// Open submenu on desktop devices

const menuItems = document.querySelectorAll('.menu-item-has-children');

menuItems.forEach(item => {
	item.addEventListener('mouseenter', () => {
		document.body.classList.add('is-submenu');
	});

	item.addEventListener('mouseleave', () => {
		document.body.classList.remove('is-submenu');
	});
});

// Open or Close mobile menu

const mobileButtonMenu = document.getElementById('mobile-button-menu');

if (mobileButtonMenu) {
	mobileButtonMenu.addEventListener('click', () => {
		document.body.classList.toggle('is-menu-open');
		mobileButtonMenu.classList.toggle('is-active');
	})
}

// Anchor checeker to open or close mobile menu 
const anchorItems = document.querySelectorAll('.header__navigation li a[href*="#"]');

anchorItems.forEach(anchor => {
	anchor.addEventListener('click', function (e) {
		const target = anchor.getAttribute('href');
		const currentPath = window.location.pathname;
		const targetPath = target.split('#')[0];

		if (currentPath === targetPath) {
			document.body.classList.remove('is-menu-open');
		}
	});
});

// Open popup on offer icon click

function popupClose(popup) {
	popup.classList.remove('popup--is-active');
}

const offerItems = document.querySelectorAll('.section-offer__single');
const popup = document.querySelector('.popup');
const popupContent = document.querySelector('.popup__content');
const popupCloseButton = document.querySelector('#popup-close');

offerItems.forEach(item => {
	const icon = item.querySelector('.section-offer__icon').outerHTML;
	const name = item.querySelector('.section-offer__name').outerHTML;
	const description = item.querySelector('.section-offer__description').innerHTML ?? '';

	item.addEventListener('click', function (e) {
		const popupContentHTML = `
				${icon}
				${name}
				<div class="popup__description">${description}</div>
		`;
		popupContent.innerHTML = popupContentHTML;
		popup.classList.add('popup--is-active');
	})
})

popupCloseButton.addEventListener('click', function (e) {
	popupClose(popup);
});

document.addEventListener('keydown', function (event) {
	if (event.key === 'Escape' || event.key === 'Esc') {
		popupClose(popup);
	}
});


// Clipboard

import ClipboardJS from 'clipboard';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const clipboard = new ClipboardJS('.share');

const buttons = document.querySelectorAll('.share');

buttons.forEach(button => {
	tippy(button, {
		content: 'Skopiuj link',
		trigger: 'mouseenter',
	});
});

clipboard.on('success', function (e) {
	const instance = e.trigger._tippy;
	if (instance) {
		instance.setContent('Skopiowano!');
		instance.show();

		setTimeout(() => {
			instance.hide();
		}, 2000);

		setTimeout(() => {
			instance.setContent('Skopiuj link');
		}, 2500);
	}

	e.clearSelection();
});


clipboard.on('error', function (e) {
	const instance = e.trigger._tippy;
	if (instance) {
		instance.setContent('Błąd kopiowania!');
		instance.show();

		setTimeout(() => {
			instance.hide();
		}, 2000);

		setTimeout(() => {
			instance.setContent('Skopiuj link');
		}, 2500);
	}
});

import Swiper from 'swiper';
import 'swiper/css';


const swiper = new Swiper('.swiper', {
	spaceBetween: 20,
	slidesPerView: 'auto',
	loop: true,
	breakpoints: {
		767: {
			slidesPerView: 2,
			spaceBetween: 30,
		},
		992: {
			slidesPerView: 3,
			spaceBetween: 30,
		},
		1279: {
			slidesPerView: 3,
			spaceBetween: 75,
		},
	}
});